import mParticle, { MPConfiguration } from '@mparticle/web-sdk';
import * as Sentry from '@sentry/browser';
import { loadIntercom, trackEvent, updateIntercom } from 'next-intercom';
import { getUserAttributes, getUserId } from 'services/auth';
import { logError } from 'services/logging';
import {
  ButtonTapTrackingEvent,
  ScreenTrackingEvent,
  TrackingEvent,
  UserAttributeEvent,
  UserAttributeType,
} from './events';

// const isDevOrUITestEnv = process.env.ENVIRONMENT === 'dev' || process.env.ENVIRONMENT === 'uitest';
const isDevOrUITestEnv = true; // set to true for all envs on 23/11/2023 because we're winding up mparticle usage

const showDebug = false;

export const initAnalytics = async () => {
  if (isDevOrUITestEnv) {
    return;
  }

  const mParticleConfig: MPConfiguration = {
    isDevelopmentMode: process.env.MPARTICLE_ENVIRONMENT === 'development',
    identityCallback: identityResult => {
      const mParticleID = identityResult.getUser().getMPID();
      Sentry.setContext('mParticle', {
        mParticleID,
      });
    },
  };
  mParticle.init(process.env.MPARTICLE_KEY ?? '', mParticleConfig);

  loadIntercom({
    appId: process.env.INTERCOM_APP_ID,
    custom_launcher_selector: '.intercom-custom-launcher',
  });
};

export const logTrackingEvent = (event: TrackingEvent): void => {
  if (isDevOrUITestEnv) {
    if (showDebug) {
      // eslint-disable-next-line no-console
      console.log(event);
    }
    return;
  }

  try {
    mParticle.logEvent(event.name, mParticle.EventType.Other, {
      platform: 'web',
      ...event.data,
    });

    trackEvent(event.name, {
      platform: 'web',
      ...event.data,
    });
  } catch (error) {
    logError({ error, errorName: 'LogTrackingError' });
  }
};

export const trackUserAttribute = (event: UserAttributeEvent) => {
  if (isDevOrUITestEnv) {
    return;
  }

  try {
    // do not send Name to mparticle, as we give it first name & last name
    if (event.userAttribute !== UserAttributeType.Name) {
      const currentUser = mParticle.Identity.getCurrentUser();
      if (Array.isArray(event.value)) {
        currentUser.setUserAttributeList(event.userAttribute, event.value);
      } else {
        currentUser.setUserAttribute(event.userAttribute, event.value);
      }
    }

    // do not update intercom with first and last name, as it only takes name
    if (
      event.userAttribute !== UserAttributeType.FirstName &&
      event.userAttribute !== UserAttributeType.LastName
    ) {
      updateIntercom('update', { [event.userAttribute.toLowerCase()]: event.value });
    }
  } catch (error) {
    logError({ error, errorName: 'TrackUserAttributeError' });
  }
};

export const logButtonTapEvent = (event: ButtonTapTrackingEvent, location: string) => {
  if (isDevOrUITestEnv) {
    return;
  }

  try {
    mParticle.logEvent('ButtonTap', mParticle.EventType.Other, {
      text: event.text,
      screen: location,
      platform: 'web',
    });

    trackEvent('ButtonTap', {
      text: event.text,
      screen: location,
      platform: 'web',
    });
  } catch (error) {
    logError({ error, errorName: 'LogButtonTapEventError' });
  }
};

export const logScreenTrackingEvent = (event: ScreenTrackingEvent) => {
  if (isDevOrUITestEnv) {
    return;
  }

  try {
    mParticle.logPageView(event.to, {
      from: event.from,
      ...(event.query && { query: event.query }),
      platform: 'web',
    });
    trackEvent('Viewed', {
      to: event.to,
      from: event.from,
      ...(event.query && { query: event.query }),
      platform: 'web',
    });
  } catch (error) {
    logError({ error, errorName: 'LogScreenTrackingEventError' });
  }
};

// Unlike app, loginToAnalytics tracks both customierId and email
// for both mParticle an Intercom
export const loginToAnalytics = async () => {
  if (isDevOrUITestEnv) {
    return;
  }

  const { email } = await getUserAttributes();
  const customerid = await getUserId();
  const userIdentities = { email, customerid };
  mParticle.Identity.login({ userIdentities }, loginCallback);

  updateIntercom('update', {
    user_id: customerid,
    email,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loginCallback = (response: any) => {
  if (response.httpCode !== 200) {
    logError({
      error: new Error(`loginToAnalytics Error - ${response.body}`),
      errorName: 'LoginCallbackError',
    });
  }
};
